import React, { FC } from 'react'
import NextHead from 'next/head'

interface MetaTagsProps {
  meta: Array<{ name: string, content: string }>
}

const MetaTags: FC<MetaTagsProps> = ({ meta }) =>
  <NextHead>
    {meta.length > 0 && meta.map(tag =>
      <meta name={tag.name} content={tag.content} key={tag.name} />
    )}
  </NextHead>

export default MetaTags

interface LinkRelProps {
  rel: string
  href: string
  crossOrigin?: string
  type?: string
  as?: string
}

export const LinkRel: FC<LinkRelProps> = ({ rel, href, crossOrigin, type, as }) => {
  let url = '';

  if (rel === 'canonical' && href.indexOf('?') >= 0) { // rimuove eventuali parametri querystring
    [url] = href.split('?')
  } else {
    url = href
  }

  return <NextHead>
    <link
      rel={rel}
      href={url}
      crossOrigin={crossOrigin || null}
      type={type || null}
      as={as || null}
    />
  </NextHead>
}
