import React, { FC } from 'react'
import Image from 'next/image'
import { theme } from 'src/themes'
import { Badge } from '@chakra-ui/react'

interface FilterProps {
  isSelected: boolean
  label: string
  icon: string
  iconAlt?: string
  onClick?(): any
  isMobile?: boolean
}

const FilterTagImage: FC<FilterProps> = ({ isSelected, label, icon, iconAlt, onClick, isMobile }) => {
  const borderColor = isSelected
    ? `${theme.colors.green['600']}, 0 2px 8px 0 rgba(44, 190, 97, 0.13)`
    : theme.colors.borders.grey

  return <Badge
    suppressHydrationWarning
    as="button"
    variant="outline"
    p={2}
    m={1}
    h={isMobile ? '4.563rem' : '4.688rem'}
    w={isMobile ? '5.625rem' : ''}
    borderRadius={4}
    textTransform="none"
    boxShadow={`inset 0 0 0px 1px ${borderColor}`}
    color="baseNeutral.700"
    onClick={onClick}
  >
    <Image src={icon} alt={iconAlt} width="40" height="40" />
    <div>{label}</div>
  </Badge>
}

export default FilterTagImage
