import React, { FC } from 'react'
import NextHead from 'next/head'
import Script from 'next/script'
import { useTranslation } from 'react-i18next'
import { default as sliderData } from 'src/containers/data/slider.json'

interface HeadProps {
  title?: string
}

const Head: FC<HeadProps> = ({ title }) => {
  const { t } = useTranslation()

  return <NextHead>
    <title>
      {title || t('common:header.title')}
      {' '}| CarPlanner
    </title>
    <link rel="preload" crossOrigin="anonymous" type="font/woff2" href="https://static1.carplanner.com/fonts/Montserrat-Regular.woff2" as="font" />
    <link rel="preload" crossOrigin="anonymous" type="font/woff2" href="https://static1.carplanner.com/fonts/Montserrat-Medium.woff2" as="font" />
    <link rel="preload" crossOrigin="anonymous" type="font/woff2" href="https://static1.carplanner.com/fonts/Montserrat-SemiBold.woff2" as="font" />
    <link rel="preload" as="image" href={sliderData.image} />
    <link rel="preload" as="image" href={sliderData.boxes[0].image} />
    <link rel="preload" as="image" href={sliderData.boxes[1].image} />
    <Script
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_ID}
      data-blockingmode="auto"
      type="text/javascript"
      async
    />
  </NextHead>
}

export default Head
