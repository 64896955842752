import React, { FC, useEffect, useState } from 'react'
import { Button } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { theme } from 'src/themes'

interface FilterProps {
  isSelected: boolean
  hasCloseButton: boolean
  label: string
  onClick?(): any
}

const FilterTag: FC<FilterProps> = ({ isSelected, hasCloseButton, label, onClick }) => {
  const [isMounted, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const bgColor = isSelected ? theme.colors.primaryGreen['300'] : 'white'

  return <Button
    color={theme.colors.primaryGreen['900']}
    variant="outline"
    fontFamily="Montserrat-Medium"
    fontWeight="normal"
    size="xs"
    h="32px"
    mr={1}
    my={1}
    borderRadius={4}
    bg={bgColor}
    _hover={{
      backgroundColor: bgColor
    }}
    borderColor={theme.colors.primaryGreen['300']}
    userSelect="none"
    onClick={onClick}
    rightIcon={hasCloseButton ? <CloseIcon boxSize="0.7rem" /> : undefined}
  >
    {label}
  </Button>
}

export default FilterTag
