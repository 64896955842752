import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure
} from '@chakra-ui/react'

const LogoSpinner = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 35">
  <g>
    <g>
      <path fill="#2EC58D" d="M40.5,19c-3.6-3.4-9.2-3.2-12.6,0.4l-2,2.2l-0.9,1v0l-0.7,0.8l-5.1,5.5c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
   c-1.3,1.3-3.1,1.7-4.8,1.2c0,0,0,0,0,0c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.4-0.2c0,0,0,0,0,0
   c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0
   c0,0-0.1-0.1-0.1-0.1l0,0c-1.9-1.8-2-4.9-0.2-6.8c1.8-1.9,4.9-2,6.8-0.2c0.6,0.5,1,1.2,1.2,1.8l3-3.2c-0.4-0.6-0.8-1.2-1.4-1.7
   c-3.6-3.4-9.2-3.2-12.6,0.4c-2.9,3.1-3.2,7.6-1,11c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.2
   c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2C8.9,31.3,9,31.4,9,31.4c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.2
   c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2
   c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c3.6,2.8,8.8,2.5,12-0.9l2-2.1c0,0,0.9-1,0.9-1l0.7-0.8l5.1-5.6c0,0,0,0,0,0
   c1.8-1.9,4.9-2,6.8-0.2c1.9,1.8,2,4.9,0.2,6.8c-1.8,1.9-4.9,2-6.8,0.2c-0.6-0.5-1-1.2-1.2-1.8l-3,3.2c0.4,0.6,0.8,1.2,1.4,1.7
   c3.6,3.4,9.2,3.2,12.6-0.4C44.3,28,44.1,22.3,40.5,19z"/>
      <path fill="#2EC58D" d="M45.8,7.7C42.3,2.6,36.7,0,29.2,0C22.8,0,15.6,3,9.5,8.3C3.6,13.4,0,19.7,0,25.1h5.4c0-3.7,2.9-8.6,7.7-12.7
   c5-4.3,11-6.9,16.1-6.9c2.3,0,4.3,0.3,6,0.8c0,0,0.1,0,0.1,0.1c4.7,2.1,7.1,5.9,8.3,9.5c0.7,2.6,1.1,5.7,1.1,9.3l5.4,0
   c0,0,0,0,0,0h0C50.1,17.6,48.7,11.9,45.8,7.7z"/>
    </g>
  </g>
</svg>

const Spinner = ({ isVisible }) => {
  const { onClose } = useDisclosure()

  return <Modal isOpen={isVisible} onClose={onClose} isCentered>
    <ModalOverlay bg='blackAlpha.700' />
    <ModalContent background="transparent" alignItems="center" boxShadow="none">
      <div className="spinner-circle" />
      <div className="spinner-logo">
        <LogoSpinner />
      </div>
    </ModalContent>
  </Modal>
}

export default Spinner
