import React, { FC, useEffect, useState } from 'react'
import { Box, Switch } from '@chakra-ui/react'

interface FilterProps {
  title: string
  label: string
  isSelected: boolean
  onChange?: any
}

const FilterSwitch: FC<FilterProps> = ({ title, label, isSelected, onChange }) => {
  const [checked, setChecked] = useState(isSelected);
  const [isMounted, setMount] = useState(false);

  useEffect(() => {
    setChecked(isSelected)
  }, [isSelected])

  useEffect(() => {
    setMount(true);
  }, [])

  if (!isMounted) {
    return null;
  }

  const onSwitchChange = evt => {
    const { target: { value } } = evt
    setChecked(value === '1')
    onChange(evt)
    return (value === '1')
  }

  return <>
    <Box
      flex="1"
      textAlign="left"
      textTransform="uppercase"
      fontFamily="Montserrat-SemiBold"
    >
      {title}
    </Box>
    <Box as="span" fontFamily="Montserrat-Medium" fontSize="0.875rem" color="#656b72">
      {label}
    </Box>
    <Switch
      colorScheme="green"
      isChecked={checked}
      float="right"
      value="1"
      onChange={onSwitchChange}
    />
  </>

}

export default FilterSwitch
