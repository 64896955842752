import React from 'react'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'
import { wrapper } from 'src/redux'
import { getIndexPageData } from 'src/utils/getStaticProps'

import { Head, LinkRel } from 'src/components/common'

const BlogPostContainer = dynamic(() => import('src/containers/BlogPostContainer'))
const PressContainer = dynamic(() => import('src/containers/PressContainer'))
const ReviewsContainer = dynamic(() => import('src/containers/ReviewsContainer'))
const SocialProofContainer = dynamic(() => import('src/containers/SocialProofContainer'))
const WhyCarPlannerContainer = dynamic(() => import('src/containers/WhyCarPlannerContainer'))
const SliderContainer = dynamic(() => import('src/containers/SliderContainer'))
const BrandContainer = dynamic(() => import('src/containers/BrandContainer'))
const InfoBoxContainer = dynamic(() => import('src/containers/InfoBoxContainer'))
const HomeNltOffersContainer = dynamic(() => import('src/containers/HomeNltOffersContainer'))
const HomeSellOffersContainer = dynamic(() => import('src/containers/HomeSellOffersContainer'))
const ServicesSectionContainer = dynamic(() => import('src/containers/Sections/ServicesContainer'))

interface Props {
  blogData: Array<any>
  pressData: Array<any>
}

const Home: NextPage<Props> = ({ blogData, pressData }) => {
  const { t } = useTranslation()

  return (
    <>
      <Script
        id="Trustpilot"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        type="text/javascript"
        async
        defer
      />
      <Head />
      <LinkRel rel="canonical" href="https://www.carplanner.com/" />

      <SliderContainer />
      <Box px={{ base: 'l', md: null }} bgColor="baseWhite" pt="xxl6">
        <HomeSellOffersContainer
          title={t('indexPage:sell_offers.title')}
          action={t('indexPage:sell_offers.action')}
          alt={t('indexPage:sell_offers.alt')}
        />
      </Box>
      <Box pb="14" px={{ base: 'l', md: null }} bgColor="baseWhite" pt="xl">
        <HomeNltOffersContainer
          title={t('indexPage:nlt_offers.title')}
          action={t('indexPage:nlt_offers.action')}
          alt={t('indexPage:nlt_offers.alt')}
        />
      </Box>
      <ServicesSectionContainer />
      <WhyCarPlannerContainer title={t('indexPage:why_cp.title')} />
      <InfoBoxContainer
        title={t('indexPage:nlt_info.title')}
        description={t('indexPage:nlt_info.description')}
        action={t('indexPage:nlt_info.action')}
        actionTitle={t('indexPage:nlt_info.actionTitle')}
        href="noleggio-a-lungo-termine/faq"
      />
      <SocialProofContainer title={t('indexPage:social_proof.title')} />
      <BrandContainer title={t('indexPage:brands.title')} />
      <ReviewsContainer title={t('indexPage:reviews.title')} />
      <PressContainer title={t('indexPage:press.title')} data={pressData} />
      <BlogPostContainer
        title={t('indexPage:blog.title')}
        action={t('indexPage:blog.action')}
        alt={t('indexPage:blog.alt')}
        data={blogData}
      />
    </>
  )
}

export const getStaticProps = wrapper.getStaticProps(store => async () => {
  const { data } = await getIndexPageData(store)

  return { props: { ...data }, revalidate: 1 }
})

export default Home
