import React, { FC } from 'react'
import { Checkbox } from '@chakra-ui/react'
import { theme } from 'src/themes'

interface FilterProps {
  isChecked: boolean
  label: string
  onChange?(): any
}

const FilterTagCheckbox: FC<FilterProps> = ({ isChecked, label, onChange }) => (
  <Checkbox
    fontFamily="Montserrat-Medium"
    color="baseDark"
    borderColor={theme.colors.primaryGreen['900']}
    colorScheme="primaryGreenCheckboxFilter"
    isChecked={isChecked}
    display="block"
    size="lg"
    lineHeight="1.2"
    mb="s"
    className="filterCheck"
    onChange={onChange}
  >
    {label}
  </Checkbox>
)

export default FilterTagCheckbox
