import React, { FC } from 'react'
import styled from 'styled-components'

const StyledSpace = styled.span`
  display: block;
  height: ${({ theme }) => theme.spaces.spacing_xxl_5};
`

const SpaceXXL5: FC = () => <StyledSpace>{''}</StyledSpace>

export default SpaceXXL5
